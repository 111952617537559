<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <TabView>
            <TabPanel>
              <template #header>
                <span> Ativos ({{ ativosList.length }}) </span>
              </template>
              <!-- 1 - Ativa, 2 - Pendente, 3 -  Suspensa, 9 - Nao Descontada, 10 - Desc. a Menor, 12 - Fora da Margem,  14 - Mov. Parcial  -->
              <DataTable
                class="p-datatable-sm"
                dataKey="id"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll"
                :paginator="true"
                :rows="5"
                :loading="loading"
                :value="ativosList"
                :filters.sync="filtros"
                :rowsPerPageOptions="rowsPerPageOptions"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'rubrica.nome',
                  'mesReferencia',
                  'anoReferencia',
                  'anoMes',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                stripedRows
                removableSort>
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column
                  :sortable="true"
                  field="anoMes"
                  header="Ano/Mês"></Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Natureza">
                  <template #body="{ data }">
                    {{ data.natureza ? data.natureza.nome : '-' }}
                  </template>
                </Column>
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Quitados ({{ quitadosList.length }}) </span>
              </template>
              <!--6 - Aguardando Supervisor, 7 - Nao Aprovada Supervisor, 8 - Baixada, 11 - Cancelamento, 13 - Solicitado Suspensao, 99 - Carga  -->
              <DataTable
                class="p-datatable-sm"
                dataKey="id"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll"
                :paginator="true"
                :rows="5"
                :loading="loading"
                :value="quitadosList"
                :filters.sync="filtros"
                :rowsPerPageOptions="rowsPerPageOptions"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'rubrica.nome',
                  'mesReferencia',
                  'anoReferencia',
                  'anoMes',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                stripedRows
                removableSort>
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column
                  :sortable="true"
                  field="anoMes"
                  header="Ano/Mês"></Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Natureza">
                  <template #body="{ data }">
                    {{ data.natureza ? data.natureza.nome : '-' }}
                  </template>
                </Column>
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Cancelados ({{ canceladosList.length }}) </span>
              </template>
              <!-- 4 - Cancelada, 5 - Quitada  -->
              <DataTable
                class="p-datatable-sm"
                dataKey="id"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll"
                :paginator="true"
                :rows="5"
                :loading="loading"
                :value="canceladosList"
                :filters.sync="filtros"
                :rowsPerPageOptions="rowsPerPageOptions"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'rubrica.nome',
                  'mesReferencia',
                  'anoReferencia',
                  'anoMes',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                stripedRows
                removableSort>
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column
                  :sortable="true"
                  field="anoMes"
                  header="Ano/Mês"></Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Natureza">
                  <template #body="{ data }">
                    {{ data.natureza ? data.natureza.nome : '-' }}
                  </template>
                </Column>
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Outros ({{ outrosList.length }}) </span>
              </template>
              <!--6 - Aguardando Supervisor, 7 - Nao Aprovada Supervisor, 8 - Baixada, 11 - Cancelamento, 13 - Solicitado Suspensao, 99 - Carga  -->
              <DataTable
                class="p-datatable-sm"
                dataKey="id"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll"
                :paginator="true"
                :rows="5"
                :loading="loading"
                :value="outrosList"
                :filters.sync="filtros"
                :rowsPerPageOptions="rowsPerPageOptions"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'rubrica.nome',
                  'mesReferencia',
                  'anoReferencia',
                  'anoMes',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                stripedRows
                removableSort>
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column
                  :sortable="true"
                  field="anoMes"
                  header="Ano/Mês"></Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Natureza">
                  <template #body="{ data }">
                    {{ data.natureza ? data.natureza.nome : '-' }}
                  </template>
                </Column>
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
    <TemplateRelatorio v-if="gerarRelatorio" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import { servidorStore } from '@/stores/servidor'
import ConsignacaoService from '@/service/ConsignacaoService'
import MatriculaService from '@/service/MatriculaService'
import TemplateRelatorio from '@/components/shared/relatorio/TemplateRelatorio.vue'
import Relatorio from '@/domain/Relatorio.js'
import RelatorioService from '@/service/RelatorioService.js'
import { storeRelatorio } from '@/stores/relatorio'

export default {
  components: { TemplateRelatorio: TemplateRelatorio },
  setup() {
    const store = servidorStore()
    const relatorioStore = storeRelatorio()
    return { store, relatorioStore }
  },

  data() {
    return {
      ativos: [
        'ATIVA',
        'PENDENTE',
        'SUSPENSA',
        'NAO_DESCONTADA',
        'DESCONTO_A_MENOR',
        'FORA_DA_MARGEM',
        'SOLICITADO_SUSPENSAO',
        'MOVIMENTO_PARCIAL',
      ],
      cancelados: ['CANCELADA', 'CANCELAMENTO', 'SOLICITADO_CANCELAMENTO'],
      quitados: ['QUITADA'],
      quitadosList: [],
      // outros: ["AGUARDANDO_SUPERVISOR", "NAO_APROVADA_SUPERVISOR", "BAIXADA", "CARGA"],
      ativosList: [],
      outrosList: [],
      canceladosList: [],
      relatorio: new Relatorio(),
      filtros: {},
      consignacao: {
        rubrica: {},
      },
      loading: false,
      idMatricula: this.$route.params.id,
      status: [
        'Ativa',
        'Pendente',
        'Quitada',
        'Cancela',
        'Suspensa',
        'Aguardando Supervisor',
        'Não Aprovada Supervisor',
        'Baixada',
        'Não descontada',
        'Solicitada Suspensão',
        'Solicitado Cancelamento',
        'Fora da margem',
        'Desconto a Menor',
      ],
      gerarRelatorio: false,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.servidorCPF = this.store.getServidor.cpf
    this.relatorioService = new RelatorioService(this.$http)
    this.service = new ConsignacaoService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.loading = true
    this.carregaConsignacoes()
  },

  methods: {
    carregaConsignacoes() {
      if (this.store.dados.id !== undefined && this.store.dados.id !== null) {
        this.idMatricula = this.store.dados.id
      }
      this.matriculaService
        .getMatricula(this.idMatricula)
        .then((res) => {
          this.store.$reset()
          this.store.dados = res
          this.store.configuracoes = res.configuracoes
          this.loading = false
          this.loadingSpinner = false
          this.store.checkValidation()
          this.filtrarStatusConsignacao()
        })
        .catch((err) => {
          this.loading = false
          this.loadingSpinner = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.$router.back()
        })
    },

    filtrarStatusConsignacao() {
      this.ativosList = []
      this.canceladosList = []
      this.quitadosList = []
      this.outrosList = []

      for (const consignacao of this.store.dados.consignacoes) {
        consignacao.anoMes =
          consignacao.anoReferencia +
          '/' +
          ('0' + consignacao.mesReferencia).slice(-2)
        if (this.ativos.includes(consignacao.statusConsignacao)) {
          this.ativosList.push(consignacao)
        } else if (this.cancelados.includes(consignacao.statusConsignacao)) {
          this.canceladosList.push(consignacao)
        } else if (this.quitados.includes(consignacao.statusConsignacao)) {
          this.quitadosList.push(consignacao)
        } else {
          this.outrosList.push(consignacao)
        }
      }
    },

    toggle(event) {
      this.$refs.menu.toggle(event)
    },

    formatarValor(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        statusConsignacao: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        'rubrica.rubrica': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    exibirDetalheConsignacao(data) {
      this.consignacaoDetalheDialog = true
      this.consignacao = data
      this.$router.push({
        name: 'consignacao-detalhe',
        params: { idconsig: data.id },
      })
    },

    gerarRelatorioPDF() {
      this.relatorio.tipo = 'MOVIMENTACAO'
      this.relatorio.nome = 'ExtratoConsignacao'
      this.relatorio.contentType = 'PDF'
      this.relatorio.parametros = new Map()
      this.relatorio.parametros.set('cpf', this.servidorCPF)

      this.relatorioService.gerarRelatorioViaHtml(this.relatorio).then(
        (res) => {
          this.gerarRelatorioPorTemplateHtml('PDF', this.relatorio.nome, res)
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
      this.gerarRelatorio = false
    },

    gerarRelatorioPorTemplateHtml(contentType, nomeRelatorio, response) {
      if (response !== null && response !== undefined && response?.length > 0) {
        this.relatorioStore.contentType = contentType
        this.relatorioStore.nomeRelatorio = nomeRelatorio
        this.relatorioStore.response = response
        this.gerarRelatorio = true
      } else {
        this.exibeToast('error', 'Os filtros informados não possui dados.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .p-sortable-column-icon {
  margin: 0 0 0 0 !important;
}

.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}

.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}

.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}

.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}
</style>
